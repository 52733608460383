import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { getAdminAuditHistory } from 'src/redux/thunks';
//ui
import { Helmet } from 'react-helmet-async';
import { Box, Typography, Container, Paper, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
//components
import EnhancedTable from 'src/components/shared/EnhancedTable';
import { findMostVulnerableProcess } from '../app/ViewReports/utils';
import { createPrescriptionPDF } from '../app/ViewReports/generateAuditPDF';

const PrescriptionList = () => {

    const audit = useSelector(state => state.adminReports.audit)
    const isAuditLoading = useSelector(state => state.adminReports.isAuditLoading)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getAdminAuditHistory())
    }, [])


    const openDownload = async (row) => {
        if (row.accessCode) {
            let process = findMostVulnerableProcess(row) // This function will find the most vulnerable process for the given row

            if (process) {
                const dynamicContent = {
                    assessor: row.assessorSortName,
                    notes: process.notes || 'N/A',
                    inhibitor: process.inhibitor,
                    selection: process.selection,
                    name: process.processName,
                    dateAssessed: row.dateAssessed.seconds, // Assuming dateAssessed is a timestamp
                    organization: row.organizationAssessed
                };

                // Create the PDF and download it
                try {
                    await createPrescriptionPDF(dynamicContent)
                } catch (error) {
                    console.error("Error creating prescription PDF:", error);
                }
            }
            else {
                console.error("Prescription not found for the given process selection.");
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Admin | Prescriptions</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" >
                        Prescriptions
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2" >
                        View prescription content for an assessment below
                    </Typography>
                </Container>
                {/* Table */}
                <Box mt={4}>
                    <Container maxWidth='lg'>
                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <EnhancedTable
                                headCells={headCells}
                                rows={audit}
                                isLoading={isAuditLoading}
                                openDownload={openDownload}
                            />
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

const headCells = [
    { id: 'organizationAssessed', numeric: false, disablePadding: true, label: 'Company Assessed' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project Name' },
    { id: 'dateAssessed', numeric: true, disablePadding: false, label: 'Assessed Date' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'assessorSortName', numeric: false, disablePadding: false, label: 'Assessor' },
    { id: 'owner', numeric: false, disablePadding: false, label: 'Owner' },
    { id: 'reports', numeric: false, disablePadding: false, label: 'View Prescription' },
];


export default PrescriptionList

