import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Divider, Typography, Card, CardContent, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import CircleIcon from '@material-ui/icons/Circle'
import Footer from 'src/components/shared/Footer';


const businessIntro = () => {

    return (
        <>
            {/* Section */}
            <Typography color="textPrimary" pb={2}>
                <b>Assemble Your Thoughts or Your Assessor Team</b>: Gather the people in your organization who are in-
                the-know about process status ratings—even if it is only you. After starting, if you determine that you do
                not have the right people “in the room” to accurately rate each process, then click the [Quit] button to
                close the assessment (no ratings are saved). If you are the only one assessing the organization, it may
                require only about 40 minutes. Assessor teams could take up to a couple of hours for team members to
                reach a shared understanding of their status.
            </Typography>

            {/* Section */}
            <Typography color="textPrimary" pb={2}>
                Set Assessment Boundaries: Assessor(s) should clarify what is and what is not being evaluated
                (Examples: Entire business or name of portion of business such as Department, Team, Alliance, Project,
                etc.) Select a name for the “project” being assessed—it will appear alongside the organization name on
                the cover of the Report.
            </Typography>

            {/* Section */}
            <Typography color="textPrimary" gutterBottom variant="h5" pt={2} pb={2}>
                Take Notes:
            </Typography>
            <Typography color="textPrimary" pb={2}>
                As you rate the level of each process, the assessment provides a place to enter notes to help remind you
                of the existing or missing basis for your ratings when you receive your Report. If you are taking the Audit
                assessment, the Note fields are required.
            </Typography>

            {/* Section */}
            <Typography color="textPrimary" gutterBottom variant="h5" pt={2} pb={2}>
                Rate Your Processes
            </Typography>
            <Typography color="textPrimary" pb={2}>
                The <i>Process Maturity Steps</i> diagram to guide your ratings of how well your business currently achieves
                the Statement Description within each process.
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center' }} pb={2}>
                <Typography color="textPrimary" style={{ width: 368 }}>
                    While you may initially think your business has
                    achieved a Level 2, if it has not completed all aspects
                    of Level 2, <b>you must rate it lower,</b> in this case, Level 1.
                    In other words, your business <i>must demonstrate <u>all</u> of
                        the positive accomplishments of <u>all</u> the lower Levels
                        before you can select a higher Level as your rating.</i>
                </Typography>
                <img width="300" style={{ marginTop: -15 }} alt="Logo" src="/static/images/vsba/Stair-level-image.png" />
            </Box>
            <Typography color="textPrimary" pb={2}>
                You will see four <u>Process Categories:</u> <b>Product, Market, Business, and Commercialization</b>. Inside each
                category there are eight <u>Processes</u> to be rated. Each Process page has a process name, a statement of
                the aspiration condition, and four process <u>Maturity Level Descriptions</u> to compare your status against.
                <b> Notice that they build up from bottom to top</b>. Rate the Level that best represents your current status.
            </Typography>

            {/* Section */}
            <Typography color="textPrimary" gutterBottom variant="h5" pt={2} pb={2}>
                Take your time.
            </Typography>

            <Typography color="textPrimary" pb={2} >
                Use the <b>[Back]</b> and <b>[Next]</b> buttons to move through the Processes sequentially or use the left-side
                navigation menu to go directly to a desired Category and Process.
            </Typography>

            <List disablePadding={true}>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                    <Typography color="textPrimary">
                        <b>Rate all 32 processes:</b> You will not be allowed to <b>[Submit]</b> your ratings until all processes have been
                        rated. Unrated processes or empty notes will display as <span style={{ color: 'red' }}>red</span> as incomplete.
                    </Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                    <Typography color="textPrimary">
                        If you <b>[Quit]</b> prior to completion, YOU WILL LOSE any ratings / notes to that point. YOU WILL HAVE
                        TO START ANEW because all ratings should be based in the same time frame.
                    </Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                    <Typography color="textPrimary">
                        <b>Submit your Assessment:</b> After making all your selections, click the [<b>Submit</b>] Button to send your
                        assessment to be analyzed. At this point the assessment is complete.
                    </Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                    <Typography color="textPrimary">
                        <b>View, Print or Download your Report:</b> Click the [<b>View Reports</b>] button on your My Panel page to
                        display a list of all your completed assessments. To see the customized Report for the assessment
                        you want, click the [View] button. Buttons on the top of the screen allow you to Download three
                        versions of your Reports (Main, Executive and Notes.)
                    </Typography>
                </ListItem>
            </List>

            <Typography color="textPrimary" pb={2} pt={2}>
                We hope you discover something new, or have something you already know reinforced, by taking the
                assessment. Best wishes to you for a prosperous future.
            </Typography>
        </>
    )
}

const auditIntro = () => {
    return (
        <>
            <Box sx={{ border: 2, borderColor: '#ffcb00', borderRadius: 1, p: 1, textAlign: 'center' }}>
                <Typography sx={{ margin: 'auto', width: '60%' }} color="textPrimary" gutterBottom pt={2} pb={2}>
                    This is a thorough <span style={{ color: 'red', fontWeight: 800 }}>Audit</span> of influential business processes.
                    Set aside at least 90 minutes of your time!
                </Typography>
            </Box>

            <Box>
                <Box pt={2} pb={1} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography mr={1}>1.</Typography>
                    <Typography>
                        <b>This</b> <span style={{ color: 'red', fontWeight: 800 }}>Audit</span> Business Assessment requires both <span style={{ color: 'red', fontWeight: 800 }}>Maturity Ratings</span> and <span style={{ color: 'red', fontWeight: 800 }}>Notes</span> to be completed on each assessed business process to complete the status collection.
                    </Typography>
                </Box>
                <List disablePadding={true} dense={true}>
                    <ListItem>
                        <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -0.5 }} />
                        <Typography color="textPrimary">
                            Rate every process accurately for your current state.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -0.5 }} />
                        <Typography color="textPrimary">
                            Enter detailed Notes for <u>every</u> rating to indicate why you selected that rating.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography pt={1.5} pb={1.5} color="textPrimary" variant='h5' sx={{ textAlign: 'center', width: 400, margin: 'auto' }}>
                            Notes are required—they’re used to justify ratings
                            and guide improvement recommendations.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -0.5 }} />
                        <Typography color="textPrimary">
                            A first experience with this Assessment could take 90 minutes for a thorough rating.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -0.5 }} />
                        <Typography color="textPrimary">
                            Team based review methods could take longer.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -0.5 }} />
                        <Typography color="textPrimary">
                            Follow-up assessments take a lot less time.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                        <Typography color="textPrimary">
                            If you need to [<b>Quit</b>] the Assessment <u><i>prior to completion</i></u>, <b>the system will not store your Ratings and Notes</b>, and YOU WILL HAVE TO SELECT AND ENTER THEM AGAIN when you return.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                        <Typography color="textPrimary">
                            Some clients in this situation have recorded Ratings and Notes outside of the system to quickly fill in upon their return. That said, status is fleeting—it is best to rate everything at one setting.
                        </Typography>
                    </ListItem>
                </List>
            </Box>
            <Box pt={2}>
                <Typography sx={{ fontWeight: 800 }}>
                    Get Ready to Assess:
                </Typography>
            </Box>

            <Box pt={2} pb={1} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography mr={1}>2.</Typography>
                <Typography>
                    <b>Collect Your Assessor Team and/or Your Thoughts:</b> Gather the team or information from those who are in-the-know about process statuses. Although groups take more time to reach mutual understanding, a team’s shared Assessment experience can be valuable.
                </Typography>
            </Box>

            <Box pt={2} pb={1} sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography mr={1}>3.</Typography>
                <Typography>
                    <b>Set Assessment Boundaries:</b> Assessor(s) should clarify what is and what is not being evaluated (Examples: Entire business or name of portion of business such as Department, Team, Alliance, Project, etc.) Select a name for the “project” being assessed as an identifier on the Report.
                </Typography>
            </Box>

            <Box pt={2} pb={2}>
                <Typography sx={{ fontWeight: 800 }}>
                    Rate Your Processes & Enter Notes:
                </Typography>
            </Box>

            <Box style={{ display: 'flex', alignItems: 'center' }} pb={2}>
                <Typography color="textPrimary" style={{ width: 368 }}>
                    Use the <i>Process Maturity Steps</i> diagram to guide your ratings of how well your business currently achieves the over-all <i>Statement Description</i> associated with your Product/Service, Market, Business, and Commercialization processes. Read and think about everything on each rating page—then select the <u>Maturity Level Description</u> that best matches your current state.
                </Typography>
                <img width="300" style={{ marginTop: -15 }} alt="Logo" src="/static/images/vsba/Stair-level-image.png" />
            </Box>
            <Typography color="textPrimary">
                Your business must demonstrate everything described in a Level to claim that status. If any attribute is missing, <b>you must rate yourself at the lower Level</b>. In other words, your business must <i>exhibit <u>all</u> of the accomplishments within a Level plus <u>all</u> the lower Levels before you can go for that particular Level </i> as your rating.
            </Typography>
            <Typography pt={2} color="textPrimary">
                Then enter examples or justification <b>Notes</b> for your rating—<span style={{ color: 'red' }}>Notes are required</span> in your <span style={{ color: 'red' }}>Audit</span> Assessment.
            </Typography>

            <Box pt={2} pb={1}>
                <Typography sx={{ fontWeight: 800 }}>
                    Complete the Assessment:
                </Typography>
            </Box>
            <Typography color="textPrimary" pt={1} pb={2} >
                Use the <b>[Back]</b> and <b>[Next]</b> buttons to move through the Processes sequentially or use the left-side
                navigation menu to go directly to a desired Category and Process.
            </Typography>

            <List disablePadding={true} dense={true}>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -4.5 }} />
                    <Typography color="textPrimary">
                        <b>Rate all 32 processes:</b> You cannot [<b>Submit</b>] your ratings until <b><u><i>all</i></u></b> processes have been rated.
                        Unrated processes and/or empty Notes boxes will display as <span style={{ color: 'red' }}>red</span> on the left-side navigation menu.
                    </Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                    <Typography color="textPrimary">
                        <b>Should you encounter the incomplete warning</b> when you click [<b>Submit</b>] the left side menu of processes will display <span style={{ color: 'red' }}>red</span> to highlight incomplete processes to be rated and annotated.
                    </Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                    <Typography color="textPrimary">
                        <b>Submit your Assessment:</b> After making <b><u><i>all</i></u></b> your selections, click the [<b>Submit</b>] Button to send your Assessment to be analyzed. Your Assessment is complete.
                    </Typography>
                </ListItem>
            </List>

            <Box pt={2} pb={1}>
                <Typography sx={{ fontWeight: 800 }}>
                    View, Print or Download your Report on the My Panel page
                </Typography>
            </Box>
            <List disablePadding={true} dense={true}>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -2.5 }} />
                    <Typography color="textPrimary">
                        Click the [<b>View Reports</b>] button on your My Panel page to display a list of completed Assessments.
                    </Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -0.5 }} />
                    <Typography color="textPrimary">
                        Click the [<b>View</b>] button on the right to see a given assessment report.
                    </Typography>
                </ListItem>
                <ListItem>
                    <CircleIcon sx={{ fontSize: 8, marginRight: 2, marginTop: -0.5 }} />
                    <Typography color="textPrimary">
                        Click Download buttons at the top of the report page for the <i>Main, Executive and Notes</i> reports.
                    </Typography>
                </ListItem>
            </List>

            <Typography pt={2}>
                By taking this <span style={{color: 'blue'}}>VIA</span>-<span style={{color: 'red'}}>Audit</span> Assessment, we hope you discover something new, or have something you already know reinforced. Best wishes to you for a prosperous future.
            </Typography>
        </>
    )
}

const AssessmentDetails = (props) => {

    window.scroll({
        top: top,
        left: 0,
        behavior: "smooth",
    });
    const {
        assessmentType
    } = props

    const classes = useStyles();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/app/take', { replace: true })
    }

    const handleNext = () => {
        navigate('/app/information', { replace: true })
    }
    useEffect(() => {
        document.getElementById('details-top').scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    console.log(assessmentType)
    return (
        <>
            <Helmet>
                <title>Vector Reports | Information</title>
            </Helmet>
            <Box sx={{ my: 8 }} id='details-top'> {/* Header */}
                {/* <Container maxWidth='md' >
                    <Box mb={4}>
                        <Typography color="textPrimary" variant="h2" >
                           Instructions
                        </Typography>
                        <Typography color="textSecondary"  gutterBottom  variant="body2" >
                            Enter Company or Organization data below
                        </Typography>
                    </Box>
                 </Container> */}
                <Container maxWidth="md" mt={2}>
                    <Card>
                        <CardContent>
                            {/*  back / next buttons */}
                            <Box px={8} mt={4} className={classes.buttonContainer}>
                                <Button variant="contained" color="error" sx={{ width: 100 }}
                                    onClick={() => handleBack()}>
                                    Back
                                </Button>
                                <Box className={classes.buttonsRight} >
                                    <Button variant="contained" sx={{ width: 100, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}
                                        onClick={() => handleNext()}>
                                        Next
                                    </Button>
                                </Box>
                            </Box>
                            <Box px={8} mt={4}>
                                <Box mt={4}>
                                    <Typography color="textPrimary" gutterBottom variant="body1" fontSize={20} pb={2}>
                                        <b>Instructions:</b> Best practices to get the most out of your assessment
                                    </Typography>

                                    {assessmentType === 'VIA Audit' ? auditIntro() : businessIntro()}

                                </Box>
                            </Box>
                            {/*  back / next buttons */}
                            <Box px={8} mt={10} className={classes.buttonContainer}>
                                <Button variant="contained" color="error" sx={{ width: 100 }}
                                    onClick={() => handleBack()}>
                                    Back
                                </Button>
                                <Box className={classes.buttonsRight} >
                                    <Button variant="contained" sx={{ width: 100, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#357a38' } }}
                                        onClick={() => handleNext()}>
                                        Next
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
            <Footer />
        </>
    )
}

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '77%',
        height: '100%',
        paddingBottom: 50,
        width: '100%'
    },
    buttonsLeft: {
        display: 'flex',
        flexDirection: 'row',
    },
    buttonsRight: {
        display: 'flex',
        flexDirection: 'row',

    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

const mapStateToProps = state => ({
    assessmentType: state.takingAssessment.assessmentType,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentDetails)